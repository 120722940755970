import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@mui/material';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
import config from "../config.json";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from "@material-ui/core/Checkbox";
import { FormGroup } from '@mui/material';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    image: {
        position: 'absolute',
        left: theme.spacing(2),
        top: theme.spacing(2.5),
        color: theme.palette.grey[500],
    },
    textarea: {
        resize: "both"
    },
    title: {
        marginLeft: 30
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {/* <img src={MeasureIcon} className={classes.image} width='25' alt="" /> */}
            <Typography className={classes.title} variant="h6">{children}</Typography>
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function Filters(props) {
    const { getList, rowsPerPage, page, searchStr, filterByEmailAssociatedOrNot, setFilterByEmailAssociatedOrNot } = props;
    const [open, setOpen] = useState(true);
    const [contactingServer, setContactingServer] = useState(false);

    const [filtersList, setFiltersList] = useState(["iOS", "Android", "Web"]);
    const [sortFiltersList, setSortFiltersList] = useState(["Created Date Desc", "UserName Asc", "UserName Desc"]);
    const [searchByFiltersList, setSearchByFiltersList] = useState(["Username", "Email", "Country", "Invite Code"]);

    const handleSearchByFilterSelected = (event) => {
        props.setSearchByFilterSelected(event.target.value);
    };

    const handleSortFilterChange = (event) => {
        props.setSortFilterSelected(event.target.value);
    };

    const filterHandleChange = (index) => {
        const Obj = {...props.filtersSelected};
        Obj[index] = Obj[index] == 0 ? 1 : 0;
        props.setFiltersSelected(Obj);
    };

    const handleFilterByEmailAssociatedOrNot = async (type) => {
        const Obj = {};
        if (type === 'open') {
          Obj['open'] = !filterByEmailAssociatedOrNot[type];
        }
        if (type === 'emailAssociated') {
          Obj['emailAssociated'] = !filterByEmailAssociatedOrNot[type];
        }
        if (type === 'emailNotAssociated') {
          Obj['emailNotAssociated'] = !filterByEmailAssociatedOrNot[type];
        }
    
        setFilterByEmailAssociatedOrNot({...filterByEmailAssociatedOrNot, ...Obj});
        await getList(rowsPerPage, page, searchStr, {...filterByEmailAssociatedOrNot, ...Obj});
      }

    return (
        <div>
            <Dialog fullWidth={true} onClose={props.noConfirmationDialogAction} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle id="alert-dialog-title">{"Filters"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        
                    </DialogContentText>

                    <span style={{ marginLeft: 10 }}>Search Filters</span>
                    <FormGroup sx={{pl: 2}}>
                        {filtersList.map((name, index) => (
                            <FormControlLabel
                                key={name + index}
                                control={
                                    <Checkbox 
                                        checked={props.filtersSelected["" + index] == 1}
                                        onClick={()=> filterHandleChange(index)} 
                                    />
                                } 
                                label={name}
                            />
                        ))}
                    </FormGroup>
                    <Divider />
                    <span style={{ marginLeft: 10 }}>Search by Filters</span>
                    <MenuItem key={"SearchRadio"} value={"SearchRadio"}>
                        <RadioGroup aria-label="searchby" name="searchby" value={props.searchByFilterSelected} onChange={handleSearchByFilterSelected}>
                            {searchByFiltersList.map((name, index) => (
                                <FormControlLabel key={"" + index} value={name} control={<Radio />} label={name} />
                            ))}
                        </RadioGroup>
                    </MenuItem>

                    {/* --------------------------- filter if email is assocated with user or not ----------------------- */}
                    <Divider />
                    <span style={{ marginLeft: 10 }}>Filter By Email Associated Users</span>
                    <FormGroup sx={{pl: 2}}>
                        <FormControlLabel control={
                        <Checkbox 
                            checked={filterByEmailAssociatedOrNot.emailAssociated || filterByEmailAssociatedOrNot.both}
                            onClick={()=> handleFilterByEmailAssociatedOrNot('emailAssociated')} 
                        />
                        } label="Users associated with email" />
                        <FormControlLabel control={
                        <Checkbox 
                            checked={filterByEmailAssociatedOrNot.emailNotAssociated || filterByEmailAssociatedOrNot.both}
                            onClick={()=> handleFilterByEmailAssociatedOrNot('emailNotAssociated')} 
                        />
                        } label="Users not associated with email" />
                    </FormGroup>

                    <Divider />
                    <span style={{ marginLeft: 10 }}>Sort Filters</span>
                    <MenuItem key={"SortRadio"} value={"SortRadio"}>
                        <RadioGroup aria-label="sort" name="sort" value={props.sortFilterSelected} onChange={handleSortFilterChange}>
                            {sortFiltersList.map((name, index) => (
                                <FormControlLabel key={"" + index} value={name} control={<Radio />} label={name} />
                            ))}
                        </RadioGroup>
                    </MenuItem>

                </DialogContent>

                <DialogActions>
                    <Button variant="contained" color="primary" onClick={props.closeAction} disabled={contactingServer}>Done</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
