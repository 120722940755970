import React, { useRef, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import bgImage from '../assets/Images/TipJarBackground.png';
import logo from '../assets/Images/Royelles_White_Logo_Large 1.png';
import tipJar from '../assets/Images/tipJar.png';
import ellipse from '../assets/Images/Ellipse 1.png';
import vector from '../assets/Images/Vector.png';
import paypal from '../assets/Images/paypal.png';
import emoji1 from '../assets/Images/Emoji_01.png';
import emoji2 from '../assets/Images/Emoji_05.png';
import emoji3 from '../assets/Images/Emoji_06.png';
import IconButton from '@material-ui/core/IconButton';
import PayPalButton from './payPalButton';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        backgroundImage: `url('${bgImage}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'scroll',
    },
    main: {
        width: '550px',
        textAlign: 'center',
        fontFamily: 'Gill Sans',
        fontWeight: '400',
        fontSize: '18px',
        '@media (max-width: 600px)': {
            width: "350px",
        }
    },
    font: {
        fontFamily: 'Fontdiner Swanky',
        color: '#FFE341'
    },
    font2: {
        fontFamily: 'Fontdiner Swanky',
        color: '#FFE341',
        fontSize: '25px'
    }
}));
export default function TipJar() {
    const classes = useStyles();
    const [amount, set_amount] = React.useState(0.0);
    const [payingMode, set_payingMode] = React.useState(false);

    const launchPaypal = (tipType) => {
        if (tipType === 1) {
            set_amount(0.99);
            set_payingMode(true);
        }
        if (tipType === 2) {
            set_amount(4.99);
            set_payingMode(true);
        }
        if (tipType === 3) {
            set_amount(9.99);
            set_payingMode(true);
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.main}>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <img src={logo} width='200px' height='44px' />
                </div>
                <div style={{ minHeight: '75%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                    <section style={{ color: 'white', marginTop: '20px' }}>
                        <p className={classes.font2}>TIP JAR</p>

                        {/* <img src={tipJar} /> */}
                        {payingMode && <PayPalButton amount={amount} />}

                        <ul style={{ listStyle: 'none', margin: '0px 10px', padding: '0px' }}>
                            <li style={{ margin: '5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img style={{ paddingRight: '20px' }} src={emoji1} />
                                    <span style={{ paddingRight: '5px' }}>GENEROUS</span>
                                </div>
                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                    <IconButton edge="start" color="inherit" onClick={() => launchPaypal(1)} aria-label="close">
                                        <img src={vector} />
                                        <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>$0.99</span>
                                    </IconButton>
                                </div>
                            </li>
                            <li style={{ margin: '5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img style={{ paddingRight: '20px' }} src={emoji2} />
                                    <span style={{ paddingRight: '20px' }}>A-MA-ZING</span>
                                </div>
                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                    <IconButton edge="start" color="inherit" onClick={() => launchPaypal(2)} aria-label="close">
                                        <img src={vector} />
                                        <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>$4.99</span>
                                    </IconButton>
                                </div>
                            </li>
                            <li style={{ margin: '5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img style={{ paddingRight: '20px' }} src={emoji3} />
                                    <span style={{ paddingRight: '20px' }}>SUPER(S)HERO</span>
                                </div>
                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                    <IconButton edge="start" color="inherit" onClick={() => launchPaypal(3)} aria-label="close">
                                        <img src={vector} />
                                        <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>$9.99</span>
                                    </IconButton>
                                </div>
                            </li>
                            <li style={{ margin: '5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {/* <img style={{paddingRight:'20px'}} src={ellipse} />
                                    <span style={{paddingRight:'5px'}}>SUPER(S)HERO</span> */}
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <img src={paypal} style={{ background: 'white' }} />
                                </div>
                            </li>
                        </ul>
                    </section>
                    <section style={{ color: 'white' }}>
                        <p>The Royelles App is dedicated to igniting 1 Million+ girls to realize their
                            greatest potential in Leadership and STEAM.</p>
                        <p className={classes.font}>HELP US ACHIEVE OUR MISSION!</p>
                        <p>Ongoing development of Royelles relies on your continued support. If this Beta version of the app
                        is inspiring a child in your life, please consider dropping a bit of change into our tip jar from time to time.
                            Any tip given will unlock our deep thanks!</p>
                    </section>
                </div>
            </div>

        </div >
    );
}